.home {
  position: relative;
  width: 90rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.main {
  margin-top: 6rem;
  display: flex;
}

.icons {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  background: rgba(36, 35, 35, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.badges {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 95%;
  bottom: 1rem;
}

.badge {
  color: #f3f4f4;
}

.icon {
  width: 3rem;
}

.heading {
  padding: 2rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  transform: translateX(3rem);
  justify-content: center;
  color: #f3f4f4;
}

.hello {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #f3f4f4;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.title {
  letter-spacing: 4px;
  color: #f3f4f4;
}

.simpraLogo {
  width: 4rem;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 8px;
  margin-left: 8px;
}

.simpraLogo:hover {
  transform: scale(1.1);
}

.text {
  margin-top: 3rem;
  font-size: 2rem;
  color: #f3f4f4;
}

.ctaButtons {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.ctaButton {
  background-color: rgba(231, 234, 236, 0.553);
  border: 1px solid transparent;
  border-radius: 1rem;
  font-size: 1.7rem;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #36454f;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0.3rem 1rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.ctaButton:hover {
  transform: translateY(-0.3rem);
  border-radius: 0.4rem;
  color: #e7eaec;
  background-color: #5b9fd8;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

@media (max-width: 58em) {
  .home {
    width: 95%;
  }
}

@media (max-width: 43em) {
  .main {
    flex-direction: column;
    align-items: center;
  }

  .heading {
    transform: translateX(0);
    align-items: center;
    justify-content: center;
    width: 80%;
    text-align: center;
  }

  .hello {
    font-size: 3rem;
  }

  .titleContainer {
    justify-content: center;
  }

  .title {
    font-size: 1.1rem;
    letter-spacing: 2px;
  }

  .text {
    font-size: 1.4rem;
  }

  .proteinLogo {
    width: 4rem;
  }
}
