.header {
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 0;
  width: 100%;
  /* background-color: #353644; */
  background: rgb(53, 54, 68);
  background: linear-gradient(
    83deg,
    rgba(53, 54, 68, 0.6) 47%,
    rgba(112, 108, 109, 0.6) 75%
  );

  clip-path: polygon(0 0, 100% 0, 100% 66%, 82% 100%, 51% 74%, 0 100%);
}

.logo {
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.logo h1 {
  color: #c3c6c7;
}

.logo img {
  cursor: pointer;
}

.logoImg {
  width: 6rem;
  height: 6rem;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button {
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 1.7rem;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #c3c6c7;
  cursor: pointer;
  transition: all 0.3s;
  width: 10rem;
}

.button:hover {
  border: 1px solid #a4969b;
  border-radius: 0.4rem;
  color: #f3f4f4;
}

.lang {
  margin-left: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag {
  width: 4rem;
}

@media (max-width: 43em) {
  .header {
    position: relative;
    margin-bottom: 3rem;
  }

  .buttons {
    display: none;
  }
}

@media (max-width: 37em) {
  .buttons {
    flex-direction: column;
    gap: 0.2rem;
  }
}

@media (max-width: 25em) {
  .header {
    clip-path: none;
    flex-direction: column;
  }

  .buttons {
    display: none;
  }
}
