.contact {
  width: 90rem;
  padding-bottom: 2rem;
  margin: 0 auto;
  margin-bottom: 6rem;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 0.8rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}

.heading {
  text-align: center;
  padding-top: 3rem;
  font-size: 4rem;
  color: #f3f4f4;
}

.emailText {
  text-align: center;
  font-size: 1.6rem;
  color: #f3f4f4;
}

.icons {
  display: flex;
  margin: 0 auto;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;
  gap: 6rem;
}

.iconGithub {
  width: 4rem;
  fill: #161b22;
  transition: all 0.3s;
}

.iconTwitter {
  width: 4rem;
  fill: #1d9bf0;
  transition: all 0.3s;
}

.iconLinkedin {
  width: 4rem;
  fill: #0b66c2;
  transition: all 0.3s;
}

.iconMail {
  width: 4rem;
  fill: #1e92d9;
  color: #1e92d9;
  transition: all 0.3s;
}

.iconGithub:hover,
.iconLinkedin:hover,
.iconTwitter:hover,
.iconMail:hover {
  transform: scale(1.1);
}

.button {
  background-color: #5b9fd8;
  border: 1px solid transparent;
  border-radius: 1rem;
  font-size: 1.7rem;
  font-family: 'Source Code Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #e7eaec;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0.3rem 1rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.button:hover {
  transform: translateY(-0.3rem);
  border-radius: 0.4rem;
  color: #e7eaec;
  background-color: #5b9fd8;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

@media (max-width: 58em) {
  .contact {
    width: 95%;
  }

  .heading {
    padding-top: 4rem;
  }
}

@media (max-width: 37em) {
  .icons {
    gap: 3rem;
  }
}

@media (max-width: 29em) {
  .icons {
    gap: 2rem;
  }

  .iconGithub,
  .iconLinkedin,
  .iconMail,
  .iconTwitter {
    width: 4rem;
  }
}

@media (max-width: 43em) {
  .heading {
    font-size: 3rem;
  }
}
